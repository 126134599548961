<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))" placeholder="项目编号/项目名称" clearable></el-input>
                    </el-form-item>
<!--	                <el-form-item label="业务模块类型:" prop="ddIdNodeBizType">-->
<!--		                <el-select v-model="filters.ddIdNodeBizType " clearable>-->
<!--			                <el-option-->
<!--					                v-for="item in ddIdNodeBizType"-->
<!--					                :key="item.id"-->
<!--					                :label="item.value"-->
<!--					                :value="item.id"-->
<!--			                >-->
<!--			                </el-option>-->
<!--		                </el-select>-->
<!--	                </el-form-item>-->
<!--	                <el-form-item  label="场所类型:" prop="ddIdVenueNature">-->
<!--		                <el-select v-model="filters.ddIdVenueNature " clearable>-->
<!--			                <el-option-->
<!--					                v-for="item in ddIdVenueNature"-->
<!--					                :key="item.id"-->
<!--					                :label="item.value"-->
<!--					                :value="item.id"-->
<!--			                >-->
<!--			                </el-option>-->
<!--		                </el-select>-->
<!--	                </el-form-item>-->
	                <el-form-item label="">
		                <el-select v-model="filters.entyStatus" placeholder="项目阶段" clearable @clear='handleClear'>
			                <el-option
					                v-for="item in entyStatusArr"
					                :key="item.value"
					                :label="item.name"
					                :value="item.value"
			                >
			                </el-option>
		                </el-select>
	                </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
            <el-table-column type='index' label="序号" width='60' :index='indexMethod' align="center"></el-table-column>
            <!-- <el-table-column type="selection" width="45"></el-table-column> -->
            <el-table-column prop="projectNo" label="审查编号" align="center" show-overflow-tooltip width="160"></el-table-column>
            <el-table-column prop="projectName" label="场所名称" align="center" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-button @click="viewsEntryName(scope.row)" type="text">{{scope.row.projectName}}</el-button>
                </template>
            </el-table-column>
<!--	        <el-table-column prop="ddIdNodeBizType" label="业务类型" align="center" show-overflow-tooltip width="100">-->
<!--		        <template slot-scope='scope'>-->
<!--			        <el-tag v-if="scope.row.ddIdNodeBizType === 1" type="success">图审</el-tag>-->
<!--			        <el-tag v-if="scope.row.ddIdNodeBizType === 10" type="danger">验收</el-tag>-->
<!--			        <el-tag v-if="scope.row.ddIdNodeBizType === 100" type="danger">开业检查</el-tag>-->
<!--		        </template>-->
<!--	        </el-table-column>-->
          <el-table-column prop="ddIdNodeBizType" label="登记状态" align="center" show-overflow-tooltip width="140">
            <template slot-scope='scope'>
              <el-tag v-if="scope.row.ddIdProjectStatus === 35||scope.row.ddIdProjectStatus === 50" type="danger">未完成登记</el-tag>
<!--              <el-tag v-else-if="scope.row.ddIdProjectStatus === 0" type="danger">未登记</el-tag>-->
              <el-tag v-else-if="scope.row.ddIdProjectStatus === 10" type="danger">整改中</el-tag>
              <el-tag v-else-if="scope.row.ddIdProjectStatus === 55" type="danger">已审结</el-tag>
              <el-tag v-else type="success">已完成登记,未审结</el-tag>
            </template>
          </el-table-column>
	        <el-table-column prop="ddIdProjectStatusName" label="项目状态" align="center" width="140" show-overflow-tooltip></el-table-column>
	        <el-table-column prop="ddIdVenueNature" label="场所类型" align="center" show-overflow-tooltip width="260">
		        <template slot-scope='scope'>
			        <div v-for="item in ddIdVenueNature">
				        <el-tag v-if="scope.row.ddIdVenueNature === item.id"  type="success">{{ item.value }}</el-tag>
			        </div>
		        </template>
	        </el-table-column>
<!--            <el-table-column prop="contractNo" label="关联合同编号" align="center" show-overflow-tooltip width="150"></el-table-column>-->
            <!-- <el-table-column prop="PerSonDefini" label="发送状态" align="center"></el-table-column> -->
            <el-table-column prop="createUserName" label="联系人" align="center" width="140"></el-table-column>
            <el-table-column prop="createUserPhone" label="手机号码" align="center" width="180"></el-table-column>
<!--            <el-table-column prop="SuccessPersonNumber" label="消防设计审查申请表" align="center" width="100">-->
<!--                <template slot-scope='scope'>-->
<!--                    <el-button v-if="scope.row.ddIdVenueNature===0" @click="viewsApplicationForm(scope.row)" type="text">查看</el-button>-->
<!--                    <el-button v-else  type="text">无</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="projectArea" label="场所面积（平方米）" align="center" width="180"></el-table-column>
<!--            <el-table-column prop="isPrequalification" label="是否预审" align="center" width="110">-->
<!--                <template slot-scope='scope'>-->
<!--                    <el-tag v-if="scope.row.isPrequalification == true" type="success">是</el-tag>-->
<!--                    <el-tag v-if="scope.row.isPrequalification == false" type="danger">否</el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column prop="ddIdProjectTypeName" label="项目类型" align="center" width="110">-->
                <!-- <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddIdProjectType == 1" type="success">普通项目</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectType == 2" type="danger">特殊项目</el-tag>
                    <el-tag v-if="scope.row.ddIdProjectType == 3" type="danger">改造项目</el-tag>
                </template> -->
<!--            </el-table-column>-->
            <!-- <el-table-column label="政府承诺制审结日期" align="center" width="110">
                <template slot-scope='scope'>
                    <div v-if="scope.row.isGovPromise">{{scope.row.projectGovPeriod}}</div>
                    <div v-else>{{scope.row.projectPeriod}}（法定）</div>
                </template>
            </el-table-column> -->
<!--            <el-table-column label="办结日期" align="center" width="110">-->
<!--                <template slot-scope='scope'>-->
<!--                    <div>{{scope.row.projectPeriod}}</div>-->
<!--                </template>-->
<!--            </el-table-column>-->
	        <el-table-column label="留言" align="center" width="150">
		        <template slot-scope='scope'>
			        <el-button type="text" @click="viewsLeaveMessage(scope.row)" >查看</el-button>
		        </template>
          </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 项目登记 -->
        <el-dialog
            title="项目登记"
            :visible.sync="projectRegisterDialog"
            v-model="projectRegisterDialog"
            :close-on-click-modal="false"
            width='1200px'
        >
            <WordTable :isDisabled="false" :projectId="needData ? needData.id : 0" :needData="needData" @closeThisDialog="closeThisDialog"></WordTable>
        </el-dialog>
        <!-- 查看项目详情 -->
        <el-dialog
            title="项目详情"
            :visible.sync="viewsApplicationInfoDialog"
            v-model="viewsApplicationInfoDialog"
            :close-on-click-modal="false"
            :width='viewsApplicationWidth'
            class="viewProject"
        >
            <ProjectView @activeChange="activeChange" :needData="needData"></ProjectView>
            <div v-if="isViewsBtn" slot="footer" class="dialog-footer">
                <el-button @click.native="viewsApplicationInfoDialog = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- 完成项目登记 -->
        <el-dialog
            title="完成项目登记"
            :visible.sync="viewsApplicationDialog"
            v-model="viewsApplicationDialog"
            :width='viewsApplicationWidth'
            class="viewProject"
        >
            <ProjectView @activeChange="activeChange" :dataHideBtn="dataHideBtn" :needData="needData"></ProjectView>
            <div v-if="isViewsBtn" slot="footer" class="dialog-footer">
<!--                <el-button type="primary" @click="showReportNoDialog" :disabled="butISdisabled" :loading="confirmRegistrationLoading">确认登记</el-button>-->
                <el-button type="primary" @click="showReportNoDialog"  :loading="confirmRegistrationLoading">确认登记</el-button>
<!--                <el-button type="primary" @click="CheckSubmit" :disabled="CheckDisabled" :loading="CheckLoading">客户核对确认</el-button>-->
                <el-button @click.native="viewsApplicationDialog = false">暂不登记</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="审查报告编号"
            :visible.sync="viewsReportNoDialog"
            v-model="viewsReportNoDialog"
            :close-on-click-modal="false"
            :width="'480px'"
            class="viewProject"
        >
            <el-form :inline='false'>
                <el-form-item label="请输入审查报告编号:">
                    <el-input v-model="reportNo" placeholder="审查报告编号" clearable></el-input>
                </el-form-item>
            </el-form>
            <p>提示: 留空则默认显示系统报告编号“清大东方消审字[2023]第XXXXX号”</p>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="_updateCustomReportNo" :loading="updateReportNoLoading">确定</el-button>
            </div>
        </el-dialog>

		<!-- 专家审核登记 -->
		<el-dialog
				    title="专家审核登记"
				    :visible.sync="techExpertDialog"
				    v-model="techExpertDialog"
				    :close-on-click-modal="false"
				>
				    <el-form  label-width="150px"  >
						<el-form-item label="在线图审专家:">
							<el-select placeholder="请选择" style="width: 300px;" prop="onlineExpertByUserIds" v-model="onlineExpertByUserIds" multiple filterable>
										<el-option v-for="item in restaurants2" :key="item.userInfoId" :label="item.userName"
												   :value="item.userInfoId"></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="技术部专家:">
							<el-select placeholder="请选择" style="width: 300px;" prop="techExpertByUserId" v-model="techExpertByUserId" filterable>
												<el-option v-for="item in restaurants2" :key="item.userInfoId" :label="item.userName"
														   :value="item.userInfoId"></el-option>
							</el-select>
						</el-form-item>

				    </el-form>
				    <div slot="footer" class="dialog-footer">
				        <el-button @click.native="techExpertDialog = false">取消</el-button>
				        <el-button type="primary" @click="techExpertSubmit" :loading="techExpertLoading">确认</el-button>
				    </div>
				</el-dialog>
<!--复审登记-->
      <el-dialog
          title="指派图审人员（驻场、在线专家-3可不选）"
          :visible.sync="assignTaskVisible"
          v-model="assignTaskVisible"
          :close-on-click-modal="false"
          :width="'50%'"
      >
        <div  class="autocomplete-container">
          <el-row class="demo-autocomplete" label-width="120px">
            <el-col >
              <span class="sub-title">驻场图审工程师：</span>
				<el-select placeholder="请选择" style="width: 300px;" prop="localExpertByUserIds" v-model="localExpertByUserIds" multiple filterable>
              				<el-option v-for="item in restaurants" :key="item.userInfoId" :label="item.userName"
              						   :value="item.userInfoId"></el-option>
				</el-select>
            </el-col>


            <el-col>
              <span class="sub-title">在线图审专家 ：</span>
				<el-select placeholder="请选择" style="width: 300px;" prop="onlineExpertByUserIds" v-model="onlineExpertByUserIds" multiple filterable>
              				<el-option v-for="item in restaurants1" :key="item.userInfoId" :label="item.userName"
              						   :value="item.userInfoId"></el-option>
				</el-select>
            </el-col>
			
			<el-col >
			  <span class="sub-title">见 习 工 程 师 &nbsp;&nbsp;&nbsp;&nbsp; ：</span>
				  <el-select placeholder="请选择" style="width: 300px;" prop="practiceExpertByUserIds" v-model="practiceExpertByUserIds" multiple filterable>
					<el-option v-for="item in restaurantsByPractice" :key="item.userInfoId" :label="item.userName"
							   :value="item.userInfoId"></el-option>
				  </el-select>
			</el-col>
            <el-col >
              <span class="sub-title">技 术 部 专 家 &nbsp;&nbsp;&nbsp;&nbsp; ：</span>
				<el-select placeholder="请选择" style="width: 300px;" prop="techExpertByUserId" v-model="techExpertByUserId" filterable>
								<el-option v-for="item in restaurants2" :key="item.userInfoId" :label="item.userName"
										   :value="item.userInfoId"></el-option>
			</el-select>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="assignTaskCancle">取消</el-button>
          <el-button @click.native="assignMultipleTaskConfirm" :loading="assignTaskLoading" type="primary">确认</el-button>
        </div>
      </el-dialog>
		<!-- 新建开业检查项目  -->
	    <el-dialog
			    :title='isAdd?(isAddForm?"新建开业检查项目":"新建项目"):"编辑"'
			    :visible.sync="addFormDialog"
			    v-model="addFormDialog"
			    :close-on-click-modal="false"
			    width='50%'
	    >
		    <el-form :model="addForm" :rules="addNewEntryRules"  ref="addNewEntryRef" label-width="160px" style='min-width:800px'>
			    <el-form-item style="width: 80%" v-if="!isAddForm" label="合同编号:" prop="contractId">
				    <el-input v-model='addForm.contractId' clearable disabled></el-input>
			    </el-form-item>
			    <el-form-item style="width: 80%" v-if="!isAddForm" label="项目名称:" prop="projectName">
				    <el-input v-model='addForm.projectName' clearable></el-input>
			    </el-form-item>
          <el-form-item style="width: 80%" v-if="isAddForm" label="场所名称:" prop="projectName">
            <el-input v-model='addForm.projectName' clearable></el-input>
          </el-form-item>
			    <el-form-item style="width: 80%" v-if="isAddForm" label="申请单位:" prop="projectName">
				    <el-input v-model='addForm.contractor' clearable></el-input>
			    </el-form-item>
			    <el-form-item style="width: 80%" v-if="isAddForm" label="场所地址:" prop="projectName">
				    <el-input v-model='addForm.address' clearable></el-input>
			    </el-form-item>
			    <!-- <el-form-item label="项目类型:" prop="entryType">
							<el-select v-model="addNewEntryForm.entryType" clearable>
									<el-option
											v-for="item in entryTypeArr"
											:key="item.value"
											:label="item.name"
											:value="item.value"
									>
									</el-option>
							</el-select>
							<span v-if="addNewEntryForm.entryType == 31">
									法定审结期限：15天
							</span>
							<span v-if="addNewEntryForm.entryType == 32 || addNewEntryForm.entryType == 33">
									法定审结期限：30天
							</span>
					</el-form-item> -->
			    <el-form-item v-if="!isAddForm" label="是否预审:" prop="isPrequalification" >
				    <el-radio v-model="addForm.isPrequalification" label="false">否</el-radio>
				    <el-radio v-model="addForm.isPrequalification" label="true">是</el-radio>
				    <div style="margin-top: -15px;font-size: 12px;color: #888;">若选择为预审项目，则该项目的图审服务不计费</div>
			    </el-form-item>
			    <el-form-item v-if="!isAddForm" label="项目分类:" prop="ddIdProjectType">
				    <el-select v-model="addForm.ddIdProjectType" clearable>
					    <el-option
							    v-for="item in projectTypeArr"
							    :key="item.value"
							    :label="item.name"
							    :value="item.value"
					    >
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <el-form-item v-if="!isAddForm"  label="工程分类:" prop="ddIdEngineeringType">
				    <el-select v-model="addForm.ddIdEngineeringType " clearable>
					    <el-option
							    v-for="item in engineeringTypeArr"
							    :key="item.value"
							    :label="item.name"
							    :value="item.value"
					    >
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <el-form-item v-if="!isAddForm" label="业务模块类型:" prop="ddIdNodeBizType">
				    <el-select v-model="addForm.ddIdNodeBizType " clearable>
					    <el-option
							    v-for="item in ddIdNodeBizType"
							    :key="item.id"
							    :label="item.value"
							    :value="item.id"
					    >
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <el-form-item v-if="isAddForm" label="场所类型:" prop="ddIdVenueNature">
				    <el-select v-model="addForm.ddIdVenueNature " clearable>
					    <el-option
							    v-for="item in ddIdVenueNature"
							    :key="item.id"
							    :label="item.value"
							    :value="item.id"
					    >
					    </el-option>
				    </el-select>
			    </el-form-item>
			    <!--                <el-form-item label="审查时限:" prop="firstReportOverdueNums">-->
			    <!--                    <el-input class="areaSty" v-model='addNewEntryForm.firstReportOverdueNums' clearable></el-input>天 ( 三位图审工程师审图截止期限 )-->
			    <!--                </el-form-item>-->
			    <!--                <el-form-item label="复核时限:" prop="reviewReportOverdueNums">-->
			    <!--                    <el-input class="areaSty" v-model='addNewEntryForm.reviewReportOverdueNums' clearable></el-input>天 ( 技术部工程师意见汇总截止期限 )-->
			    <!--                </el-form-item>-->
			    <el-form-item v-if="!isAddForm" label="审查报告编号(合格):" prop="customReportNo">
				    <el-input v-model='addForm.customReportNo' clearable></el-input>
			    </el-form-item>
			    <el-form-item v-if="!isAddForm" label="审查报告编号(不合格):" prop="customFailReportNo">
				    <el-input v-model='addForm.customFailReportNo' clearable></el-input>
			    </el-form-item>
			    <el-form-item class="spacilTimer" label="承诺办结日期:" prop="projectPeriod">
				    <el-date-picker
						    v-model="addForm.projectPeriod"
						    type="date"
						    placeholder="选择日期"
				    >
				    </el-date-picker> ( 项目终审截止时间 )
			    </el-form-item>
			    <!-- <el-form-item label="政府承诺制审结期限:">
							<el-input v-model='addNewEntryForm.endTimer' clearable class="timerSty" :disabled="addNewEntryForm.noEndTimer"></el-input>
							天
							<el-checkbox v-model="addNewEntryForm.noEndTimer" class="noTimerSty">政府无承诺时间</el-checkbox>
					</el-form-item> -->
			    <el-form-item label="图审面积:" prop="projectArea">
				    <el-input style="width: 75%" class="areaSty" v-model='addForm.projectArea' clearable></el-input>平方米
			    </el-form-item>
			    <el-form-item class="spacilTimer" v-if="!isAddForm" label="项目说明:">
				    <el-input style="width: 75%" v-model='addForm.projectNotes' type="textarea" :rows="3"></el-input>
				    <div style="font-size: 12px;color: #888;">根据当地建设局要求表述</div>
			    </el-form-item>
          <el-form-item class="spacilTimer" v-if="isAddForm" label="场所概况:">
            <el-input style="width: 75%" v-model='addForm.projectNotes' type="textarea" :rows="3"></el-input>
            <div style="font-size: 12px;color: #888;">根据当地建设局要求表述</div>
          </el-form-item>
			    <el-form-item label="项目联系人:" prop="projectContacts">
				    <el-input style="width: 75%" v-model='addForm.projectContacts' clearable></el-input>
			    </el-form-item>
			    <el-form-item label="手机号码:" prop="projectPhone">
				    <el-input style="width: 75%" v-model='addForm.projectPhone' clearable></el-input>
			    </el-form-item>
		    </el-form>
		    <div slot="footer" class="dialog-footer">
			    <el-button @click.native="addFormDialog = false">取消</el-button>
			    <el-button v-if="isAddForm" type="primary" @click="addNewFormSubmit" :loading="addNewEntryLoading">确认</el-button>
			    <el-button v-if="!isAddForm" type="primary" @click="addNewEntrySubmit" :loading="addNewEntryLoading">确认</el-button>
		    </div>
	    </el-dialog>
	    <el-dialog
			    title='退回修改'
			    :visible.sync="rollBackDialog"
			    v-model="rollBackDialog"
			    :close-on-click-modal="false"
			    width='50%'
	    >
		    <el-form :model="rollBackForm"  ref="rollBackForm" label-width="160px">
			    <el-form-item label="退回原因:" prop="rollBackReason">
				    <el-input type="textarea" :rows="5" style="width: 80%" v-model='rollBackForm.rollBackReason' clearable></el-input>
			    </el-form-item>
			    <el-form-item label="退回备注（文件）:" prop="rollBackRemark">
				    <el-upload
						    class="upload-demo"
						    :action="uploadImagesUrl"
						    :data="{projectId: needData.id}"
						    :multiple = 'false'
						    :headers="headers"
						    :before-upload="beforeUpload"
						    :on-success="handle_success"
						    :on-error="handle_error"
						    :on-remove="handleRemove"
						    :show-file-list="true"
				    >
<!--					    <i class="el-icon-upload"></i>-->
					    <el-button size="small" type="primary">点击上传</el-button>
				    </el-upload>
			    </el-form-item>
		    </el-form>
		    <div slot="footer" class="dialog-footer">
			    <el-button @click.native="rollBackDialog = false">取消</el-button>
			    <el-button type="primary" @click="rollBackSubmit" :loading="addNewEntryLoading">确认</el-button>
		    </div>
	    </el-dialog>
	    <el-dialog
		    title='留言'
		    :visible.sync="messageViewDialog"
		    v-model="messageViewDialog"
		    :close-on-click-modal="false"
		    width='60%'
	    >
		    <el-table :data="messageArr"
		              style="width: 100%"
		              :highlight-current-row='true'
		              border
		    >
			    <el-table-column prop="content" label="留言内容" width="">
				    <template slot-scope="scope">
					    <el-input type="textarea" :rows="2" style="width: 100%" v-model="scope.row.content" readonly></el-input>
				    </template>
			    </el-table-column>
			    <el-table-column prop="createTime" label="留言时间" width="120">
				    <template slot-scope="scope">
					    {{scope.row.createTime.split('T')[0]}}
				    </template>
			    </el-table-column>
			    <el-table-column prop="userName" label="留言备注" width="350">
				    <template slot-scope="scope">
					   <div v-for="item in JSON.parse(scope.row.fileUrl)">
						   <el-button type="text" @click="showReportFile(item)" style="margin-right: 5px">{{item.sourceName}}</el-button>
						   <br>
					   </div>
				    </template>
			    </el-table-column>
		    </el-table>
	    </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import WordTable from "./wordTable";
import { getButtonList } from "../../promissionRouter";
import {
	getDrProjectFiledtList,
	getProjectList,
	addProject,
	updateProject,
	getCurrentDrContract,
	registerProject,
	getProjectById,
	getDrContractById,
	getProjectFileInfo,
	updateCustomReportNo,
	getExpertDataDictRelationList,
	registerReviewProject,
	registerMultipleReviewProject,
	registerExpertProject,
	getProjectListTime,
	conclusionProject,
	getByNamespace,
	addProjectApplication, getProjectApplicationByProjectId, returnUploadKYJCProjectStatus,
	getProjectMessageByProjectId, generateTemporaryUrlByFileName, addProjectMessage,
} from '@/api/api'
import ProjectView from "../drProjectManager/projectView.vue";
import { validPhone, isDigit, validString } from '../../../util/validate'
import api from "@/api";
export default {
    components:{Toolbar,WordTable,ProjectView},
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
	        messageViewDialog:false,
					fileList:[],
	        uploadImagesUrl:'',
	        rollBackForm:{
		        rollBackReason:undefined,
		        rollBackRemark:undefined,
	        },
	        dataHideBtn:false,
	        isAddForm:false,
	        ddIdVenueNature:[],
	        ddIdNodeBizType:[
		        {id: 1,value: "审查"},
		        {id: 10,value: "验收"},
		        {id: 100,value: "开业检查"},
	        ],
          //驻场专家1
          localExpertOneByUserId: 0,
          //驻场专家2
          localExpertTwoByUserId: 0,
          //驻场专家3
          localExpertThreeByUserId:0,
          //在线图审专家1
          onlineExpertOneByUserId: 0,
          //在线图审专家2
          onlineExpertTwoByUserId: 0,
          //在线图审专家2
          onlineExpertThreeByUserId: 0,
		  localExpertByUserIds: [],
		  onlineExpertByUserIds: [],
          //技术部专家
          techExpertByUserId: null,
		  //见习工程师
		  practiceExpertByUserIds: [],
          assignTaskLoading:false,
          restaurants: [],
          restaurants1: [],
          restaurants2: [{ "userInfoId": 30, "userName": "冷启贞-总部-技术部-工程师" }, {
            "userInfoId": 31,
            "userName": "刘金刚-总部-技术部-工程师"
          }, { "userInfoId": 238, "userName": "李红蕾-总部-技术部-工程师" }, {
            "userInfoId": 239,
            "userName": "郭营-总部-技术部-工程师"
          }, { "userInfoId": 244, "userName": "杨健鹏-总部-技术部-工程师" }, {
            "userInfoId": 252,
            "userName": "庞建军-总部-技术部-工程师"
          }, { "userInfoId": 312, "userName": "李宝萍-总部-技术部-工程师" }],
		restaurantsByPractice : [],
          state1: '',
          state2: '',
          state3: '',
          state4: '',
          state5: '',
          state6: '',
          state7: '',
          assignTaskVisible:false,
            utils: '',
            buttonList: [],
            needData: '',
            filters: {
                keyWord: '',
	            ddIdVenueNature:undefined,
	            ddIdNodeBizType:undefined,
	            entyStatus:'35,50',
            },
		        entyStatusArr: [
			        // { name: '未提交申请', value: '10' },
			        { name: '未完成登记', value: '35,50' },
			        { name: '已完成登记,未审结', value: '44,45,46,140,47,51,52,53,141,54' },
			        { name: '已审结', value: '55' },
			        // { name: '全部', value:' ' },
		        ],
            smsTemplateArr: [],
            pushTemplateArr: [],
            sendSmsStatusArr: [
                {Name:'待发送',value:0},
                {Name:'发送中',value:1},
                {Name:'已发送',value:2},
            ],
            modelTypeArr: [
                {label:'短信模板',value:0},
                {label:'推送模板',value:1},
            ],
            sendCrowdArr: [],
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            isAdd: true,
            // 新建项目
            addNewEntryDialog: false,
            addFormDialog: false,
            addNewEntryLoading: false,
	        addForm: {
		        coefficient:undefined,//难度系数
		        contractId:undefined,//合同id
		        customFailReportNo:undefined,//客户自定流水编号(不合格)
		        customReportNo:undefined,//审查报告编号
		        ddIdConclusionStatus:undefined,//审结状态
		        ddIdEngineeringType:undefined,//工程分类，157特殊建筑，158其他建筑
		        ddIdNodeBizType:undefined,//业务模块类型（字典命名空间：CommonService.WorkFlow.NodeBizType）
		        ddIdProjectType:undefined,//项目类型1普通项目，2特殊项目，3改造项目
		        ddIdVenueNature:undefined,//场所类型（字典命名空间：BizSevice.DrProject.VenueNature）
		        id:undefined,//
		        isGovPromise:undefined,//是否政府承诺制审结期限
		        isPrequalification:undefined,//是否预审
		        projectArea:undefined,//图审面积
		        projectCompany:undefined,//项目申请单位
		        projectContacts:undefined,//联系人
		        projectGovPeriodDay:undefined,//政府承诺制审结期限天数
		        projectName:undefined,//项目名称
		        projectNotes:undefined,//备注
		        projectPeriod:undefined,//法定审结期限/办结日期
		        projectPhone:undefined,//手机号
		        firstReportOverdueNums: 2,//审查时限
		        reviewReportOverdueNums: 1,
		        contractor:undefined,//建设单位
		        address:undefined,//工程地址
	        },

            addNewEntryRules:{
	            // contractId:[{required:true,message:'请输入合同编号',trigger:'blur'}],
	            projectName:[{required:true,message:'请输入项目名称',trigger:'blur'}, { validator: validString}],
	            ddIdProjectType:[{required:true,message:'请选择项目类型',trigger:'blur'}],
	            // projectPeriod:[{message:'请输入政府承诺制审结期限',trigger:'blur'}],
	            projectArea:[{required:true,message:'请输入图审面积',trigger:'blur'}],
	            projectContacts:[{required:true,message:'请输入项目联系人',trigger:'blur'}],
	            ddIdVenueNature:[{required:true,message:'请输入场所类型',trigger:'change'}],
	            ddIdNodeBizType:[{required:true,message:'请选择业务模块类型',trigger:'change'}],
	            projectPhone:[
                    {required:true,message:'请输入手机号码',trigger:'blur'},
                    { validator: isPhoneNumberNum },
                ],
                // 20230208新加
	            isPrequalification:[{required:true,message:'请选择是否预审',trigger:'blur'}],
	            ddIdEngineeringType:[{required:true,message:'请选择工程分类',trigger:'blur'}],
	            projectPeriod:[{required:true,message:'请选择办结日期',trigger:'blur'}],
                // firstReportOverdueNums: [
                //     {required:true,message:'请输入审查时限',trigger:'blur'},
                //     { validator: isDigit },
                // ],
                // reviewReportOverdueNums: [
                //     {required:true,message:'请输入复核时限',trigger:'blur'},
                //     { validator: isDigit },
                // ],
            },
            entryTypeArr: [
                {name: "普通项目",value: 31},
                {name: "特殊项目",value: 32},
                {name: "改造项目",value: 33},
            ],
            projectTypeArr: [
                {name: "新建项目",value: 31},
                {name: "扩建项目",value: 32},
                {name: "改造项目",value: 33},
            ],
            engineeringTypeArr: [
                {name: "特殊建设工程",value: 157},
                {name: "其他建设工程",value: 158},
            ],
            currentContract:{},
            // 项目登记
            projectRegisterDialog: false,
            blueprintFileList: [],
            defaultProps: {
                children: 'childFiles',
                label: 'fileName',
            },
            butISdisabled: true,
            isViewsBtn: true,
            // 查看
            viewsApplicationInfoDialog: false,
            viewsApplicationDialog: false,
            activeIndex: "1",
            contractInformation: '',
            viewsApplicationWidth: '1200px',
            // loading
            confirmRegistrationLoading: false,
            CheckLoading: false,
            CheckDisabled: false,
            reportNo: '',
            viewsReportNoDialog: false,
            updateReportNoLoading: false,
			practiceExpertOptions:[],

			techExpertDialog: false,
			techExpertLoading: false,
          editConfirmTime:false,
          workTime:undefined,
	        areaList:[],
	        rollBackLoading:false,
	        rollBackDialog:false,
	        messageArr:[]

        }
    },
    methods: {
			//访问留言文件
	    showReportFile(row){
				let params = {
					fileName: row.webupurl
				}
		    generateTemporaryUrlByFileName(params).then(res => {
					window.open(res.data);
			    // console.log(res)
		    })
	    },
			
			//留言
	    // 留言
	    viewsLeaveMessage(row) {
		    let params = {
			    projectId: row.id
		    }
		    getProjectMessageByProjectId(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.messageArr = result.response
				    this.messageViewDialog = true
			    }else {
						this.$message.error(result.message)
			    }
		    })
	    },
			
	    // 图片上传之前函数
	    beforeUpload (file) {
				if (this.fileList.length>4){
					this.$message.error('最多只能上传五个文件！')
					return false
				}
		    // const isLt2M = file.size / 1024 / 1024 > 2
		    // var type = file.name.substring(file.name.lastIndexOf('.') + 1)
		    // if (type == 'jpg' || type == 'png') {} else {
			  //   this.$message.error('上传图片只能是 JPG/PNG 格式!')
			  //   return false
		    // }
		    // if (!isLt2M === false) {
			  //   this.$message.error('上传图片大小不能超过 2MB!')
			  //   return false
		    // }
	    },
	    // 图片上传成功时的函数
	    handle_success (res) {
		    // console.log(res)
		    if (res.success) {
			    this.$message.success('图片上传成功！')
			    this.uploadFileDialog = false;
			    // this.getapplication();
			    if (res.success) {
				    let resp = res.response
				    this.fileList.push({
					    sourceName: resp.sourceName,
					    webupurl: resp.webupurl
				    })
				    console.log(resp,'图片上传成功返回结果')
				    }
		    } else {
			    this.$message.error(res.message)
		    }
	    },
	    // 图片上传失败时的函数
	    handle_error (res) {
		    this.$message.error('图片上传失败！')
	    },
	    handleRemove(file,fileList){
		    console.log(fileList,'fileList11111')
				this.fileList= fileList.map(item=>({
					sourceName:item.name,
					webupurl:item.response.response.webupurl
				}))
	    },
			rollBack(){
				if (!this.needData){
				  this.$message.warning("请先选择项目！")
				  return
				}
				this.fileList = []
				if (this.needData.ddIdProjectStatus==35||this.needData.ddIdProjectStatus==50){
					this.rollBackDialog = true;
				}else {
					this.$message.warning("当前项目状态不允许退回！")
				}
			},
	    rollBackSubmit(){
		    if (this.fileList.length<=0){
			    this.$message.error("请先上传文件！")
			    return
		    }
			    console.log(this.fileList,'fileList ')
			    this.$confirm('确定要退回此项目吗？', '退回', {
				    confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
			    }).then(() => {
				    this.rollBackLoading = true
				    let formData = {
					    content:this.rollBackForm.rollBackReason,
					    fileUrl:JSON.stringify(this.fileList),
					    projectId:this.needData.id
				    }
				    addProjectMessage(formData).then(res => {
					    // if (res.data.success){
						  //   this.$message.success(res.data.message)
					    // }else{
						  //   this.$message.error(res.data.message)
					    // }
				    })
				    let params = {
					    projectId: this.needData.id,
				    }
				    returnUploadKYJCProjectStatus(params).then(res => {
					    this.rollBackLoading = false
					    if (res.data.success) {
						    this.$message.success(res.data.message)
						    this.viewsApplicationDialog = false
						    this.rollBackDialog = false
						    this.getProjectList()
					    }
					    else {
						    this.$message.error(res.data.message)
					    }
				    })
			    })
		    
			},
	    //获取区县列表
	    getAreaList(){
		    let params = {}
		    getContractList(params).then(res => {
			    let result = res.data
			    if(result.success) {
				    this.areaList = result.response
				    console.log(this.areaList,'区县列表')
			    }
		    })
	    },
			async getNamespaceArr(){
				let params = {
					namespace:"BizSevice.DrProject.VenueNature",
				}
				const res = await getByNamespace(params)
				if (res.data.success){
					this.ddIdVenueNature = res.data.response
				}

			},
			//新建开业检查项目
	    addProjectBusiness(){
		    getCurrentDrContract().then((res) => {
					    if(res.data.success) {
						    this.addForm = {
							    coefficient: undefined,//难度系数
							    contractId: undefined,//合同id
							    customFailReportNo: undefined,//客户自定流水编号(不合格)
							    customReportNo: undefined,//审查报告编号
							    ddIdConclusionStatus: undefined,//审结状态
							    ddIdEngineeringType: 158,//工程分类，157特殊建筑，158其他建筑
							    ddIdNodeBizType: 100,//业务模块类型（字典命名空间：CommonService.WorkFlow.NodeBizType）
							    ddIdProjectType: 457,//项目类型1普通项目，2特殊项目，3改造项目
							    ddIdVenueNature: undefined,//场所类型（字典命名空间：BizSevice.DrProject.VenueNature）
							    id: undefined,//
							    isGovPromise: undefined,//是否政府承诺制审结期限
							    isPrequalification: false,//是否预审
							    projectArea: undefined,//图审面积
							    projectCompany: undefined,//项目申请单位
							    projectContacts: undefined,//联系人
							    projectGovPeriodDay: undefined,//政府承诺制审结期限天数
							    projectName: undefined,//项目名称
							    projectNotes: undefined,//备注
							    projectPeriod: undefined,//法定审结期限/办结日期
							    projectPhone: undefined,//手机号
							    firstReportOverdueNums: 2,//审查时限
							    reviewReportOverdueNums: 1,
							    address:undefined,
							    contractor:undefined,
						    }
						    this.isAdd = true;
						    this.isAddForm = true;
						    this.addFormDialog = true;
								this.currentContract = res.data.response
					    }else{
						    this.$message({
							    message: '您所在区县暂无合同，无法添加项目',
							    type: 'error'
						    });
					    }
				})
	    },
	    //新建开业检查项目提交
	     addNewFormSubmit(){
		    this.$refs.addNewEntryRef.validate(async (valid) => {
			    if (valid) {
				    let params = {
					    ...this.addForm,
				    }
				    params.contractId = this.currentContract.id;
				    const res = await addProject(params)
				    if (res.data.success){
							let paras =
									{
										"projectId": res.data.response.id,
										"address": this.addForm.address,
										"approvalFile": null,
										"contacts": null,
										"contractor":this.addForm.contractor,
										"ddIdCategory": null,
										"decorationArea": null,
										"decorationFloor": null,
										"decorationPositions": [],
										"fireFacilitiess": [],
										"isChangePurpose": false,
										"isDecoration": false,
										"isKeepWarm": false,
										"isSpecialFire": null,
										"isStrengthenFire": null,
										"keepWarmFloor": null,
										"keepWarmMaterial": null,
										"keepWarmPosition": null,
										"licenseFile": null,
										"materialTypes": [],
										"notes": null,
										"originalUse": null,
										"phone": null,
										"specialSituations": [],
										"totalArea": null,
										"totalInvestment": null,
										"useCharacter": null,
										"buildReqs": [
											{
												"buildName": "",
												"structureType": "",
												"useCharacter": "",
												"fireRating": "",
												"overgroundFloors": "",
												"undergroundFloors": "",
												"height": "",
												"length": "",
												"landArea": "",
												"overgroundArea": "",
												"undergroundArea": ""
											}
										],
										"companyReqs": [
											{
												"companyTypeName": "",
												"companyName": "",
												"qualificationLevel": "",
												"corporateCard": "",
												"projectDirectorCard": "",
												"phone": ""
											}
										]
									}
							await addProjectApplication(paras)
					    console.log(res.data.response,'ressuccess')
					    this.addNewEntryLoading = false;
							this.addFormDialog = false
					    this.isAddForm = false;
							this.isAdd = false;
					    this.$message.success(res.data.message)
					    this.getDataList();
				    } else {
					    this.$message.warning(res.data.message)
				    }
			    }
		    })
	    },

      assignTaskConfirm(){
        this.assignTaskLoading= true
        this.validateInput()  //对于项目登记中复审登记按钮弹窗输入框的验证
        this.validateInputOne()
        this.validateOnlineExpertTwo()
        this.validateInput3()
        this.validateInput4()
        this.validateInput5()
        this.validateInput6()
        let params1 = {
          //驻场专家1
          localExpertOneByUserId: this.localExpertOneByUserId,
          //驻场专家2
          localExpertTwoByUserId: this.state5?this.localExpertTwoByUserId:undefined,
          //驻场专家3
          localExpertThreeByUserId: this.state6?this.localExpertThreeByUserId:undefined,
          //在线图审专家1
          onlineExpertOneByUserId: this.onlineExpertOneByUserId,
          //在线图审专家2
          onlineExpertTwoByUserId: this.state3?this.onlineExpertTwoByUserId:undefined,
          //在线图审专家3
          onlineExpertThreeByUserId: this.state7 ?this.onlineExpertThreeByUserId:undefined,
          //项目id
          projectId: this.needData.id,
          //技术部专家
          techExpertByUserId: this.techExpertByUserId
        }
        if (this.state1!==''&&this.state2!==''&&this.state2!==this.state3&&this.state4!==''&&this.state1!==this.state5){
          registerReviewProject(params1).then(res => {
            if (res.data.success) {
              this.restaurants1 = res.data.response.data;
              this.assignTaskVisible = false
              this.assignTaskLoading = false;
              this.$message.success('指定成功！')
              this.assignTaskCancle()
              this.getDataList()
            } else {
              this.$message.error('指定失败！')
              this.assignTaskCancle()
              this.assignTaskLoading = false;
            }
          })
        }else {
          this.assignTaskLoading = false;
        }

      },
	  assignMultipleTaskConfirm(){
		  this.assignTaskLoading= true

		  if(this.localExpertByUserIds.length == 0)
		  {
			  this.assignTaskLoading = false;
			  this.$message.error('驻场图审工程师不能为空！');
			  return;
		  }

		  if(this.onlineExpertByUserIds.length == 0)
		  {
			  this.assignTaskLoading = false;
			  this.$message.error('在线图审工程师不能为空！');
			  return;
		  }

		  if( this.techExpertByUserId == null )
		  {
			  this.assignTaskLoading = false;
			  this.$message.error('技术部专家不能为空！');
			  return;
		  }
		  // 获取当前日期
		  let currentDate = new Date();

		  // 格式化为YYYY-MM-DD
		  let year = currentDate.getFullYear();
		  let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1
		  let day = ("0" + (+currentDate.getDate()+1)).slice(-2);
		  console.log(day,'day')

		  // 构建格式化后的日期字符串
		  let formattedDate = year + "-" + month + "-" + day;

		  // console.log(formattedDate); // 输出格式化后的日期

		  let params = {
			  from:formattedDate,
			  to:this.needData.projectPeriod,
		  }
		  getProjectListTime(params).then(res=>{
			  if (res.data.success){
				  console.log(res,'1111')
				  this.workTime = res.data.response.workNum
				  console.log(this.workTime,'办结时长')
				  if (this.workTime>3){
					  this.editConfirmTime = true;
					  this.updataObject()
					  console.log("客户核对确认")
					  this.$confirm('确认后，才可完成复审项目登记，项目登记完成后项目信息将被固化无法修改，是否完成核对确认?',"提示",{
					  }).then(() => {
						  let apiParam = {
							  //驻场专家组
							  localExpertByUserIds: this.localExpertByUserIds,
							  //在线专家组
							  onlineExpertByUserIds: this.onlineExpertByUserIds,
							  // 见习工程师组
							  practiceExpertByUserIds: this.practiceExpertByUserIds.length > 0 ? this.practiceExpertByUserIds : undefined,
							  //项目id
							  projectId: this.needData.id,
							  //技术部专家
							  techExpertByUserId: this.techExpertByUserId
						  }

						  registerMultipleReviewProject(apiParam).then(res => {
							  if (res.data.success) {
								  this.localExpertByUserIds = [];
								  this.onlineExpertByUserIds = [];
								  this.practiceExpertByUserIds = [];
								  this.techExpertByUserId = null;
								  this.assignTaskVisible = false;
								  this.assignTaskLoading = false;
								  this.$message.success('指定成功！')
								  this.assignTaskCancle()
								  this.getDataList()
							  } else {
								  this.$message.error('指定失败！')
								  this.assignTaskCancle()
								  this.assignTaskLoading = false;
							  }
						  })
					  }).catch(() => {})
				  }else {
					  this.$message({
						  message:'距离办结日期不足3个工作日',
						  type:'warning'
					  })
					  this.assignTaskLoading= false
				  }
			  }
		  })


	  },
      assignTaskCancle () {
        this.assignTaskVisible = false
        this.state1 = ''
        this.state2 = ''
        this.state3 = ''
        this.state4 = ''
        this.state5 = ''
        this.state6 = ''
        this.state7 = ''
        //驻场专家
        this.localExpertOneByUserId = 0,
        //驻场专家
        this.localExpertTwoByUserId=0,
        //驻场专家
        this.localExpertThreeByUserId = 0,
        //在线图审专家1
        this.onlineExpertOneByUserId=0,
        //在线图审专家2
        this.onlineExpertTwoByUserId=0,
        //在线图审专家2
        this.onlineExpertThreeByUserId=0,
        //技术部专家
        this.techExpertByUserId=0
        },
      validateInput () {
        // 在失去焦点时触发的验证方法
        if (this.state1 === '') {
          // 输入为空，执行相应的验证逻辑，例如提示用户输入不能为空
          // 可以使用this.$message进行消息提示
          this.$message.error('驻场图审工程师不能为空！');
        }
      },
      validateInputOne () {
        // 在失去焦点时触发的验证方法
        if (this.state2 === '') {
          // 输入为空，执行相应的验证逻辑，例如提示用户输入不能为空
          // 可以使用this.$message进行消息提示
          this.$message.error('在线图审专家1不能为空！');
        }
      },
      validateOnlineExpertTwo () {
        // 在失去焦点时触发的验证方法
        if (this.state2===this.state3){
          this.$message.error('在线图审专家不能重复！');
        }
      },
      validateInput3 () {
        // 在失去焦点时触发的验证方法
        if (this.state4 === '') {
          // 输入为空，执行相应的验证逻辑，例如提示用户输入不能为空
          // 可以使用this.$message进行消息提示
          this.$message.error('技术部专家不能为空！');
        }
      },
      validateInput4 () {
        // 在失去焦点时触发的验证方法
          if (this.state1 === this.state5) {
            this.$message.error('驻场工程师不能重复！');
          }
          // if(this.state5 ===''){
          //   this.localExpertTwoByUserId=0
          // }

      },
      validateInput5 () {
        // 在失去焦点时触发的验证方法
        if (this.state1 === this.state6&&this.state5 === this.state6) {
          this.$message.error('驻场工程师不能重复！');
        }
        // if (this.state5 === '') {
        //   this.localExpertTwoByUserId = 0
        // }

      },
      validateInput6 () {

          if (this.state2 === this.state7&&this.state2===this.state7) {
            this.$message.error('在线图审专家不能重复！');
          }

      },
      selectExpert(){
        if (this.needData){
          this.assignTaskVisible = true
          this.getDataExpertList()
        } else {
          this.$message.warning("请先选择一项！")
        }
      },
      querySearch(queryString, cb) {
        let restaurants = this.restaurants;

        // 解决element建议搜索框无法显示内容 的数据处理
        for (let i = 0; i < restaurants.length; i++) {
          restaurants[i].value = restaurants[i].userName;
        }
        let results = queryString
            ? restaurants.filter(this.createStateFilter(queryString))
            : restaurants;
        // 调用 callback 返回建议列表的数据
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 100 * Math.random());
      },
      createStateFilter (queryString) {
        return (state) => {
          return (
              state.userName.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          );
        };
      },
      querySearch1 (queryString, cb) {
        let restaurants = this.restaurants1;
        // 解决element建议搜索框无法显示内容 的数据处理
        for (let i = 0; i < restaurants.length; i++) {
          restaurants[i].value = restaurants[i].userName;
        }
        let results = queryString
            ? restaurants.filter(this.createStateFilter(queryString))
            : restaurants;
        // 调用 callback 返回建议列表的数据
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 100 * Math.random());
      },
      querySearch2 (queryString, cb) {
        let restaurants = this.restaurants2;

        // 解决element建议搜索框无法显示内容 的数据处理
        for (let i = 0; i < restaurants.length; i++) {
          restaurants[i].value = restaurants[i].userName;
        }
        let results = queryString
            ? restaurants.filter(this.createStateFilter(queryString))
            : restaurants;
        // 调用 callback 返回建议列表的数据
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 100 * Math.random());
      },
      querySearch3 (queryString, cb) {
        let restaurants = this.restaurants2;

        // 解决element建议搜索框无法显示内容 的数据处理
        for (let i = 0; i < restaurants.length; i++) {
          restaurants[i].value = restaurants[i].userName;
        }
        let results = queryString
            ? restaurants.filter(this.createStateFilter(queryString))
            : restaurants;
        // 调用 callback 返回建议列表的数据
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 100 * Math.random());
      },
      handleSelect (item) {
        // console.log(item);
        this.localExpertOneByUserId = item.userInfoId
      },
      handleSelect1 (item) {
        // console.log(item);
        this.onlineExpertOneByUserId = item.userInfoId
      },
      handleSelect2 (item) {
        // console.log(item);
        this.onlineExpertTwoByUserId = item.userInfoId
      },
      handleSelect3 (item) {
        // console.log(item);
        this.techExpertByUserId = item.userInfoId
      },
      handleSelect4 (item) {
        // console.log(item);
        this.localExpertTwoByUserId = item.userInfoId
      },
      handleSelect5 (item) {
        // console.log(item);
        this.localExpertThreeByUserId = item.userInfoId
      },
      handleSelect6 (item) {
        // console.log(item);
        this.onlineExpertThreeByUserId = item.userInfoId
      },

        callFunction(item) {
            this[item.func].apply(this, item);
        },
      //专家查询接口
      getDataExpertList () {
        // let params = {
        //   pageSize: this.pages.pageSize,
        //   pageIndex: this.pages.pageIndex,
        //   expertType: 62,
        // }
        // this.listLoading = true
        // getExpertDataDictRelationList(params).then(res => {
        //   if (res.data.success) {
        //     this.restaurants = res.data.response.data;
        //     console.log(this.restaurants,'this.restaurants')
        //     this.pages.dataCount = res.data.response.dataCount;
        //   }
        // })
        // let params1 = {
        //   pageSize: this.pages.pageSize,
        //   pageIndex: this.pages.pageIndex,
        //   expertType: 63,
        // }
        // getExpertDataDictRelationList(params1).then(res => {
        //   if (res.data.success) {
        //     this.restaurants1 = res.data.response.data;
        //     this.listLoading = false
        //   }
        // })

		this.listLoading = true
		let params = {
		  pageSize: 200,
		  pageIndex: 1,
		}
		getExpertDataDictRelationList(params).then(res => {
			let that = this;
			if (res.data.success) {
				this.restaurants = [];
				this.restaurants1 = [];
				this.restaurantsByPractice = [];
				res.data.response.data.forEach(function(item,index){
					that.listLoading = false;
					switch(item.ddId)
					{
						case 62:
							that.restaurants.push(item);
							break;
						case 63:
							that.restaurants1.push(item);
							break;
						case 389:
							that.restaurantsByPractice.push(item);
							break;
					}
				})
			}
		})

        // let params2 = {
        //   pageSize: this.pages.pageSize,
        //   pageIndex: this.pages.pageIndex,
        //   expertType: 162,
        // }
        // getExpertDataDictRelationList(params2).then(res => {
        //   if (res.data.success) {
        //     this.restaurants2 = res.data.response.data;
        //   }
        //
        // })
      },
	    // 审结操作
	    conclusionProject () {
				
		    if (this.needData) {
			    if (this.needData.ddIdprojectStatus !== 140 || this.needData.ddIdprojectStatus !== 141){
				    this.$message.warning('当前项目状态不允许审结！')
				    return
			    }
			    this.$confirm('确定审结？', '提示', {}).then(() => {
				    let params = {
					    projectId: this.needData.id,
					    donclusionStatus: 56
				    }
				    conclusionProject(params).then(res => {
					    let result = res.data
					    if (result.success) {
						    this.$message.success(result.message)
						    this.getDataList()
					    } else {
						    this.$message.error(result.message)
					    }
				    })
			    })
		    } else {
			    this.$message.warning('请选择需要审结项！')
		    }
	    },
        // 列表查询接口
        getDataList(){
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
                searchKey: this.filters.keyWord,
	            // ddIdNodeBizType:this.filters.ddIdNodeBizType,
	            // ddIdVenueNature:this.filters.ddIdVenueNature,
	            //   provinceCode:440000,
		          //   isAudit:0,
	              multiStatus:this.filters.entyStatus,
		            ddIdNodeBizType:100,
            }
            this.listLoading = true
	        getProjectList(params).then(res => {
                let result = res.data
                if(result.success) {
                    this.tableData = result.response.data
                    this.pages.dataCount = result.response.dataCount
                }
                this.listLoading = false
            })
        },
        // 查询
        getProjectList() {
            this.pages.pageIndex = 1
            this.getDataList();
        },
        // 新建项目
        handleAdd() {
            getCurrentDrContract().then((res) => {
                if(res.data.success){
                    this.addFormDialog = true
	                  this.isAddForm = false;
                    this.addNewEntryLoading = false
                    this.isAdd = true
                    this.addForm = {
	                    coefficient:undefined,//难度系数
	                    contractId:undefined,//合同id
	                    customFailReportNo:undefined,//客户自定流水编号(不合格)
	                    customReportNo:undefined,//审查报告编号
	                    ddIdConclusionStatus:undefined,//审结状态
	                    ddIdEngineeringType:undefined,//工程分类，157特殊建筑，158其他建筑
	                    ddIdNodeBizType:undefined,//业务模块类型（字典命名空间：CommonService.WorkFlow.NodeBizType）
	                    ddIdProjectType:undefined,//项目类型1普通项目，2特殊项目，3改造项目
	                    ddIdVenueNature:undefined,//场所类型（字典命名空间：BizSevice.DrProject.VenueNature）
	                    id:undefined,//
	                    isGovPromise:undefined,//是否政府承诺制审结期限
	                    isPrequalification:undefined,//是否预审
	                    projectArea:undefined,//图审面积
	                    projectCompany:undefined,//项目申请单位
	                    projectContacts:undefined,//联系人
	                    projectGovPeriodDay:undefined,//政府承诺制审结期限天数
	                    projectName:undefined,//项目名称
	                    projectNotes:undefined,//备注
	                    projectPeriod:undefined,//法定审结期限/办结日期
	                    projectPhone:undefined,//手机号
	                    firstReportOverdueNums: 2,//审查时限
	                    reviewReportOverdueNums: 1,
                    };
                    this.currentContract = res.data.response;
                }else{
                    this.$message({
                        message: '您所在区县暂无合同，无法添加项目',
                        type: 'error'
                    });
                }
            });
        },
        // 编辑项目
        handleEdit() {
            if(this.needData) {
	            let params = {
		            projectId: this.needData.id
	            }
	            getProjectApplicationByProjectId(params).then(res=>{
		            if (res.data.success){
			            console.log(res.data.response,'app')
			            this.addForm = {
										address: res.data.response.address,
				            contractor: res.data.response.contractor,
				            ...this.needData,
			            }
		            }
							})
							this.isAddForm = false;
							if (this.needData.ddIdVenueNature){
								this.isAddForm = true;
							}
	            this.addFormDialog = true
	            this.addNewEntryLoading = false
	            this.isAdd = false

            } else {
                this.$message.warning("请选择需要修改项！")
            }
        },
        // 新建项目提交
        addNewEntrySubmit() {
            this.$refs.addNewEntryRef.validate((valid) => {
                if (valid) {
                    this.addNewEntryLoading = true;
                    let params = {
                        ...this.addForm,
                    }
                    if(this.isAdd) {
                        params.contractId = this.currentContract.id;
                        addProject(params).then(res => {
                            this.addNewEntryLoading = false;
                            this.addFormDialog = false;
                            let result = res.data
                            if(result.success) {
                                this.$message.success(result.message)
                                this.getDataList();
                            } else {
                                this.$message.warning(result.message)
                            }
                        })
                    } else {
                        params.id = this.needData.id
                        updateProject(params).then(res => {
                            this.addNewEntryLoading = false;
                            this.addFormDialog = false;
                            params.contractId = this.needData.contractId;
                            let result = res.data
                            if(result.success) {
                                this.$message.success(result.message)
                                this.getDataList();
                            } else {
                                this.$message.warning(result.message)
                            }
                        })
                    }
                }
            })
        },
        updataObject(){
          if (this.needData === null || !this.needData.id) {
            this.$message({
              message: '请选择一条数据',
              type: "error"
            });
            return
          }
          this.addForm = {
            ...this.needData
          }
          let params = {
            ...this.addForm,
            firstReportOverdueNums: this.editConfirmTime?this.workTime-1:2,
            reviewReportOverdueNums: this.editConfirmTime?1:1,
          }
          params.id = this.needData.id
          updateProject(params).then(res => {
            this.addNewEntryLoading = false;
            this.addNewEntryDialog = false;
            params.contractId = this.needData.contractId;
            let result = res.data
            if(result.success) {
              if (!this.editConfirmTime){
                this.$message.success(result.message);
                this.getDataList();
              }
              this.editConfirmTime = false;

            } else {
              this.$message.warning(result.message)
            }
          })
        },
        // 项目名称
        viewsEntryName(row) {
            this.needData = row
            this.viewsApplicationInfoDialog = true
        },
        // 消防设计审查申请表查看
        viewsApplicationForm(row) {
            this.needData = row
            this.projectRegisterDialog = true
        },
        // 查看接口请求
        viewsRequestFun(row) {
            if(this.activeIndex == '1') {
                let params = {
                    projectId: row.id
                }
                getProjectById(params).then(res => {
                    let result = res.data
                    if(result.success) {

                    }
                })
                let params1 = {
                    id: row.contractId
                }
                getDrContractById(params1).then(res => {
                    let result = res.data
                    if(result.success) {
                        this.contractInformation = result.response
                    }
                })
            } else if(this.activeIndex == '3') {
                let params = {
                    projectId: row.id
                }
                getProjectFileInfo(params).then(res => {
                    let result = res.data
                    if(result.success) {
                        this.blueprintFileList = result.response.files
                    }
                })
            }
        },
        // 导航切换
        activeChange(val) {
            if(val == '2') {
                this.viewsApplicationWidth = "1200px"
            }
						else if (val == '4'){
		            this.viewsApplicationWidth =  '1200px'
            }
						else {
                this.viewsApplicationWidth = "60%"
            }
        },
        // 完成项目登记
        // showConfirmDialog() {
	    addRegistration() {
            // this.$router.push("/wordTable");
            if(this.needData) {
	            this.dataHideBtn = true;
                this.viewsApplicationDialog = true
                this.isViewsBtn = true
                this.butISdisabled = true
                this.CheckDisabled = false
                this.viewsRequestFun(this.needData)
            } else {
                this.$message.warning("请先选择一项！")
            }
        },
        // 关闭项目登记
        closeThisDialog() {
            this.projectRegisterDialog = false
        },
        showReportNoDialog() {
          // this.reportNo = '';
          // this.viewsReportNoDialog = true;
	        if ((this.needData.ddIdProjectStatus===35||this.needData.ddIdProjectStatus===50)){
		        if(this.needData) {
			        this.$confirm('确定完成项目登记吗？', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
			        }).then(() => {
				        let params = {
					        isCompleted : true,
					        projectId  : this.needData.id
				        }
				        registerProject(params).then(res => {
					        if (res.data.success) {
						        this.$message.success(res.data.message);
						        this.viewsApplicationDialog = false
						        this.getDataList();
					        }else {
						        this.$message.error(res.data.message);
					        }
				        })
			        })
		        } else {
			        this.$message.warning("请先选择一项！")
		        }
	        }else {
						this.$message.warning("已完成登记！")
		        this.viewsApplicationDialog = false
	        }
	        
	       
        },
        // 确认登记
        confirmRegistrationSubmit() {
            this.confirmRegistrationLoading = true
            registerProject({projectId: this.needData.id}).then((res) => {
                let result = res.data
                if(result.success) {
                    this.$message.success(result.message)
                    this.viewsApplicationDialog = false
                    this.getDataList()
                } else {
                    this.$message.error(result.message)
                    this.viewsApplicationDialog = false
                    this.butISdisabled = true
                    this.CheckDisabled = false
                }
                this.confirmRegistrationLoading = false
            });
        },
        // 客户核对确认
        CheckSubmit() {
          if (this.needData.ddIdProjectType!==457||this.needData.ddIdProjectType!==415){
	          // 获取当前日期
	          let currentDate = new Date();

	          // 格式化为YYYY-MM-DD
	          let year = currentDate.getFullYear();
	          let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1
	          let day = ("0" + (+currentDate.getDate()+1)).slice(-2);

	          // 构建格式化后的日期字符串
	          let formattedDate = year + "-" + month + "-" + day;

	          // console.log(formattedDate); // 输出格式化后的日期

	          let params = {
		          from:formattedDate,
		          to:this.needData.projectPeriod,
	          }
	          getProjectListTime(params).then(res=>{
		          if (res.data.success){
			          console.log(res,'1111')
			          this.workTime = res.data.response.workNum
			          console.log(this.workTime,'办结时长')
			          if (this.workTime>3){
				          this.editConfirmTime = true;
				          this.updataObject()
				          console.log("客户核对确认")
				          this.$confirm('客户核对确认后，才可完成项目登记，项目登记完成后项目信息将被固化无法修改，是否完成客户核对确认?',"提示",{
				          }).then(() => {
					          this.butISdisabled = false
					          this.CheckDisabled = true
				          }).catch(() => {})
			          }else {
				          this.$message({
					          message:'距离办结日期不足3个工作日',
					          type:'warning'
				          })
			          }
		          }
	          })
          }else {
	          this.butISdisabled = false
	          this.CheckDisabled = true
          }
          console.log()

        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
	        console.log(val,'val')
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val){
            this.pages.pageSize = val;
            this.getDataList();

        },

		// 专家审核登记
		showExpertRegisterProject(){
			if(this.needData) {
			    this.techExpertDialog = true
			    this.techExpertLoading = false
				this.onlineExpertByUserIds = []
				this.techExpertByUserId = null

			} else {
			    this.$message.warning("请选择需要修改项！")
			}
		},

		techExpertSubmit(){
			if(!this.techExpertByUserId)
			{
				this.$message.warning("请选择技术专家！")
				return;
			}
			if(this.onlineExpertByUserIds.length == 0)
			{
				this.$message.warning("请选择在线专家！")
				return;
			}

			this.techExpertLoading = true;

			let params = {
			  //在线专家组
			  onlineExpertByUserIds: this.onlineExpertByUserIds,
			  //项目id
			  projectId: this.needData.id,
			  //技术部专家
			  techExpertByUserId: this.techExpertByUserId
			}
			
			
		
			registerExpertProject(params).then(res => {
			    this.techExpertLoading = false;
			    this.techExpertDialog = false;
				this.onlineExpertByUserIds = []
				this.techExpertByUserId = null
				
			    let result = res.data
			    if(result.success) {
			        this.$message.success(result.message)
			        this.getDataList();
			    } else {
			        this.$message.warning(result.message)
			    }
			})
		},
		
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row.projectGovPeriod || row.projectGovPeriod == ""
            ? ""
            : util.formatDate.format(new Date(row.projectGovPeriod), "yyyy-MM-dd hh:mm");
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            let currentPage = this.pages.pageIndex
            let pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 点击完成项目登记按钮事件
        // showConfirmDialog(){
        //     // todo 未完成弹窗等，先写这个接口，完成流程的测试
        //     this.$confirm('确认完成项目登记吗?', '提示', {
        //         type: 'warning'
        //     }).then(() => {
        //         registerProject({projectId:this.needData.id}).then((res) => {
        //             this.$message.success("完成项目登记")
        //         });
        //     })
        //     .catch(() => {
        //     })
        // },
        _updateCustomReportNo() {
            this.updateReportNoLoading = true;
            updateCustomReportNo({id: this.needData.id, customReportNo: this.reportNo}).then((res) => {
                let result = res.data
                if(result.success) {
                    this.viewsReportNoDialog = false;
                    this.confirmRegistrationSubmit();
                } else {
                    this.$message.error(result.message)
                }
                this.updateReportNoLoading = false
            });
        },
	    // 获取token
	    getToken () {
		    return localStorage.getItem('Token')
	    },
    },
    watch: {

    },
	computed: {
		headers () {
			return {
				'Authorization': 'Bearer ' + this.getToken() // 直接从本地获取token就行
			}
		},
		},
    created(){
        this.utils = util
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
	      this.uploadImagesUrl = api.uploadFile
        this.getDataList();
				this.getNamespaceArr()
        this.getDataExpertList();
    }
}
</script>

<style lang="stylus" scoped>
.autocomplete-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.autocomplete-container .el-col {
  flex-basis: calc(33.33% - 10px); /* Adjust width to fit three columns in one row */
  margin-bottom: 20px; /* Add space between rows */
}

    .timerSty {
        width: 100px;
    }
    .noTimerSty {
        margin-left: 20px;
    }
    .areaSty {
        width: 300px;
        margin-right: 2px;
    }
    .dialogTitle {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
    }
    .activeSty {
        margin-top: 10px;
    }
    .activeSty .el-form-item {
        margin-bottom: 0px;
    }
    .titleSpeSty {
        font-weight: bold;
    }
    .spacilTimer>>>.el-form-item__label {
        color: red!important;
        font-weight: bold;
    }
    .viewProject>>>.el-dialog__body {
        padding: 0px 20px 30px;
        margin-top: -15px;
    }
</style>
